var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col space-y-4 w-full" }, [
    _c(
      "div",
      { staticClass: "flex justify-between space-x-6 w-full" },
      [
        _c(
          "card",
          {
            staticClass: "flex-1 flex flex-col",
            attrs: { loading: _vm.animalLoading },
          },
          [
            _c(
              "base-button",
              {
                staticClass: "ml-auto",
                on: {
                  click: function ($event) {
                    return _vm.changeAnimal("animal")
                  },
                },
              },
              [_vm._v(" Change animal ")]
            ),
            _c("animal-edit", {
              attrs: {
                "hide-cancel": true,
                "show-animal-type": true,
                animal: _vm.animal,
                companies: _vm.insurances,
              },
              on: {
                submit: _vm.updateAnimal,
                cancelEdit: function (updated) {
                  return _vm.setStoreAnimal("animal", updated)
                },
              },
            }),
            _c("animal-users", {
              staticClass:
                "-m-4 bg-gray-200 border-t-4 flex flex-1 justify-between mt-2 p-4 text-sm",
              attrs: { animal: _vm.animal },
            }),
          ],
          1
        ),
        _c(
          "card",
          {
            staticClass: "flex-1 flex flex-col",
            attrs: { loading: _vm.compareAnimalLoading },
          },
          [
            _c(
              "base-button",
              {
                staticClass: "ml-auto",
                on: {
                  click: function ($event) {
                    return _vm.changeAnimal("compareAnimal")
                  },
                },
              },
              [_vm._v(" Change animal ")]
            ),
            _c("animal-edit", {
              attrs: {
                "hide-cancel": true,
                "show-animal-type": true,
                animal: _vm.compareAnimal,
                companies: _vm.compareInsurances,
              },
              on: {
                submit: _vm.updateAnimal,
                cancelEdit: function (updated) {
                  return _vm.setStoreAnimal("compareAnimal", updated)
                },
              },
            }),
            _c("animal-users", {
              staticClass:
                "-m-4 bg-gray-200 border-t-4 flex flex-1 justify-between mt-2 p-4 text-sm",
              attrs: { animal: _vm.compareAnimal },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex justify-between space-x-6 mb-4 w-full" },
      [
        _c("animal-actions", {
          staticClass: "flex-1 flex-wrap lg:space-x-3",
          attrs: {
            "animal-key": "animal",
            "compare-animal-key": "compareAnimal",
          },
        }),
        _c("animal-actions", {
          staticClass: "flex-1 flex-wrap lg:space-x-3",
          attrs: {
            "animal-key": "compareAnimal",
            "compare-animal-key": "animal",
            direction: "left",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex justify-between space-x-6 w-full" },
      [
        _c("animal-history-transfer", {
          staticClass: "flex-1 mt-2",
          attrs: {
            "animal-key": "animal",
            "compare-animal-key": "compareAnimal",
          },
        }),
        _c("animal-history-transfer", {
          staticClass: "flex-1 mt-2",
          attrs: {
            "animal-key": "compareAnimal",
            "compare-animal-key": "animal",
            direction: "left",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }