<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="flex justify-between space-x-6 w-full">
      <card
        class="flex-1 flex flex-col"
        :loading="animalLoading"
      >
        <base-button
          class="ml-auto"
          @click="changeAnimal('animal')"
        >
          Change animal
        </base-button>
        <animal-edit
          :hide-cancel="true"
          :show-animal-type="true"
          :animal="animal"
          :companies="insurances"
          @submit="updateAnimal"
          @cancelEdit="(updated) => setStoreAnimal('animal', updated)"
        />
        <animal-users
          class="-m-4 bg-gray-200 border-t-4 flex flex-1 justify-between mt-2 p-4 text-sm"
          :animal="animal"
        />
      </card>
      <card
        class="flex-1 flex flex-col"
        :loading="compareAnimalLoading"
      >
        <base-button
          class="ml-auto"
          @click="changeAnimal('compareAnimal')"
        >
          Change animal
        </base-button>
        <animal-edit
          :hide-cancel="true"
          :show-animal-type="true"
          :animal="compareAnimal"
          :companies="compareInsurances"
          @submit="updateAnimal"
          @cancelEdit="(updated) => setStoreAnimal('compareAnimal', updated)"
        />
        <animal-users
          class="-m-4 bg-gray-200 border-t-4 flex flex-1 justify-between mt-2 p-4 text-sm"
          :animal="compareAnimal"
        />
      </card>
    </div>
    <div class="flex justify-between space-x-6 mb-4 w-full">
      <animal-actions
        class="flex-1 flex-wrap lg:space-x-3"
        animal-key="animal"
        compare-animal-key="compareAnimal"
      />
      <animal-actions
        class="flex-1 flex-wrap lg:space-x-3"
        animal-key="compareAnimal"
        compare-animal-key="animal"
        direction="left"
      />
    </div>
    <div class="flex justify-between space-x-6 w-full">
      <animal-history-transfer
        class="flex-1 mt-2"
        animal-key="animal"
        compare-animal-key="compareAnimal"
      />
      <animal-history-transfer
        class="flex-1 mt-2"
        animal-key="compareAnimal"
        compare-animal-key="animal"
        direction="left"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AnimalEdit from '@/components/animal/AnimalEdit.vue';
import AnimalActions from '@/components/admin/animals/AnimalActions.vue';
import AnimalHistoryTransfer from '@/components/admin/animals/AnimalHistoryTransfer.vue';
import AnimalUsers from '@/components/admin/animals/AnimalUsers.vue';
import { AnimalsApi } from '@/api';

export default {
  components: { AnimalEdit, AnimalActions, AnimalHistoryTransfer, AnimalUsers },

  computed: {
    ...mapState('admin/animals', [
      'animal',
      'animalLoading',
      'compareAnimal',
      'compareAnimalLoading',
      'insurances',
      'compareInsurances',
    ]),
    ...mapState('admin', ['countryId']),
  },

  mounted() {
    this.fetchAnimalData({
      id: this.$route.params.animal,
      animalKey: 'animal',
      insurancesKey: 'insurances',
      locale: this.countryId,
    });
    this.fetchAnimalData({
      id: this.$route.params.compareAnimal,
      animalKey: 'compareAnimal',
      insurancesKey: 'compareInsurances',
      locale: this.countryId,
    });
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'animals') {
      this.clearAnimalsForCompare();
    }
    next();
  },

  methods: {
    ...mapActions('admin/animals', [
      'fetchAnimalData',
      'clearAnimalsForCompare',
    ]),
    ...mapMutations('admin/animals', ['setAnimal']),

    setStoreAnimal(animalKey, updated) {
      if (updated?.data?.animal) {
        this.setAnimal({ animalKey, data: updated.data.animal });
      }
    },

    changeAnimal(animalKey) {
      this.setAnimal({ animalKey, data: {} });
      this.$router.push({ name: 'animals' });
    },

    updateAnimal({ animal, done: callback }) {
      AnimalsApi.updateAnimal(animal)
        .then(res => {
          callback(true, res);

          this.$notify({
            group: 'primary',
            title: 'Success!',
            text: `${animal.name} was updated`,
          });
        })
        .catch(res => callback(false, res));
    },
  },
};
</script>

<style lang="postcss">
</style>
