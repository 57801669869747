<template>
  <div class="flex justify-between text-sm">
    <div>
      <h3 class="font-bold">
        Shared with
      </h3>
      <p
        v-for="shared in animal.shared"
        :key="shared.id"
      >
        {{ shared.user ? shared.user.display_name : '' }}
        ({{ shared.user_id }})
      </p>
      <p v-if="animal.shared && !animal.shared.length">
        -
      </p>
    </div>
    <div>
      <h3 class="font-bold">
        Owned by
      </h3>
      <p>
        {{ animal.user ? animal.user.display_name : '' }}
        ({{ animal.user_id }})
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    animal: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
