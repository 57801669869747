var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table-container",
    {
      staticClass: "text-sm",
      attrs: {
        theads: ["Type", "Info", "Veterinarian", ""],
        loading: _vm.animalHistoryLoading,
      },
    },
    [
      _vm._l(_vm.animalHistory.appointments, function (appointment) {
        return _c(
          "tr",
          { key: appointment.id, staticClass: " text-left" },
          [
            _c("table-data", [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [
                  _c("strong", [_vm._v("Video consultation ")]),
                  _c("span", [_vm._v("(" + _vm._s(appointment.id) + ")")]),
                ]),
              ]),
            ]),
            _c("table-data", [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        appointment.booking
                          ? appointment.booking.booking_datetime
                          : "Booking incomplete"
                      ) +
                      " "
                  ),
                ]),
                _c("p", [_vm._v(" " + _vm._s(appointment.status) + " ")]),
              ]),
            ]),
            _c("table-data", [
              _vm._v(
                " " +
                  _vm._s(
                    appointment.booking && appointment.booking.veterinary
                      ? appointment.booking.veterinary.display_name
                      : "-"
                  ) +
                  " "
              ),
            ]),
            _c(
              "table-data",
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.transferingAppointments.includes(
                        appointment.id
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.transferAppointment(appointment.id)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "arrow-" + _vm.direction },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._l(_vm.animalHistory.questions, function (question) {
        return _c(
          "tr",
          { key: question.id, staticClass: "bg-gray-200 text-left" },
          [
            _c("table-data", [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [
                  _c("strong", [_vm._v("Question ")]),
                  _c("span", [_vm._v("(" + _vm._s(question.id) + ")")]),
                ]),
              ]),
            ]),
            _c("table-data", [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [_vm._v(" " + _vm._s(question.title) + " ")]),
                _c("p", [_vm._v(" " + _vm._s(question.created_at) + " ")]),
                _c("p", [
                  _vm._v(
                    _vm._s(question.status === 1 ? "Answered" : "Unanswered")
                  ),
                ]),
              ]),
            ]),
            _c("table-data", [
              _vm._v(
                " " +
                  _vm._s(
                    question.veterinary ? question.veterinary.display_name : "-"
                  ) +
                  " "
              ),
            ]),
            _c(
              "table-data",
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.transferingQuestions.includes(question.id),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.transferQuestion(question.id)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "arrow-" + _vm.direction },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._l(_vm.animalHistory.voicecalls, function (voicecall) {
        return _c(
          "tr",
          { key: voicecall.id, staticClass: "bg-yellow-100 text-left" },
          [
            _c("table-data", [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [
                  _c("strong", [_vm._v("Voidecall ")]),
                  _c("span", [_vm._v("(" + _vm._s(voicecall.id) + ")")]),
                ]),
              ]),
            ]),
            _c("table-data", [
              _c("div", { staticClass: "flex flex-col space-y-1" }, [
                _c("p", [_vm._v(" " + _vm._s(voicecall.created_at) + " ")]),
                _c("p", [_vm._v(" " + _vm._s(voicecall.status) + " ")]),
              ]),
            ]),
            _c("table-data"),
            _c("table-data", [
              _vm._v(
                " " +
                  _vm._s(
                    voicecall.veterinary
                      ? voicecall.veterinary.display_name
                      : "-"
                  ) +
                  " "
              ),
            ]),
            _c(
              "table-data",
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.transferingVoiceCalls.includes(voicecall.id),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.transferVoiceCall(voicecall.id)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "arrow-" + _vm.direction },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.animalHistory.appointments &&
      !_vm.animalHistory.appointments.length &&
      _vm.animalHistory.questions &&
      !_vm.animalHistory.questions.length &&
      _vm.animalHistory.voicecalls &&
      !_vm.animalHistory.voicecalls.length
        ? _c("tr", [
            _c(
              "td",
              { staticClass: "py-3 px-6 text-base", attrs: { colspan: "6" } },
              [
                _vm._v(
                  " The animal has no previous appointments, questions or voicecalls. "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }