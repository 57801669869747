<template>
  <table-container
    :theads="['Type', 'Info', 'Veterinarian', '']"
    class="text-sm"
    :loading="animalHistoryLoading"
  >
    <tr
      v-for="appointment in animalHistory.appointments"
      :key="appointment.id"
      class=" text-left"
    >
      <table-data>
        <div class="flex flex-col space-y-1">
          <p>
            <strong>Video consultation </strong>
            <span>({{ appointment.id }})</span>
          </p>
        </div>
      </table-data>
      <table-data>
        <div class="flex flex-col space-y-1">
          <p>
            {{ appointment.booking ? appointment.booking.booking_datetime : 'Booking incomplete' }}
          </p>
          <p>
            {{ appointment.status }}
          </p>
        </div>
      </table-data>
      <table-data>
        {{ appointment.booking && appointment.booking.veterinary ? appointment.booking.veterinary.display_name : '-' }}
      </table-data>
      <table-data>
        <base-button
          :loading="transferingAppointments.includes(appointment.id)"
          @click="transferAppointment(appointment.id)"
        >
          <font-awesome-icon :icon="`arrow-${direction}`" />
        </base-button>
      </table-data>
    </tr>
    <tr
      v-for="question in animalHistory.questions"
      :key="question.id"
      class="bg-gray-200 text-left"
    >
      <table-data>
        <div class="flex flex-col space-y-1">
          <p>
            <strong>Question </strong>
            <span>({{ question.id }})</span>
          </p>
        </div>
      </table-data>
      <table-data>
        <div class="flex flex-col space-y-1">
          <p>
            {{ question.title }}
          </p>
          <p>
            {{ question.created_at }}
          </p>
          <p>{{ question.status === 1 ? 'Answered' : 'Unanswered' }}</p>
        </div>
      </table-data>
      <table-data>
        {{ question.veterinary ? question.veterinary.display_name : '-' }}
      </table-data>
      <table-data>
        <base-button
          :loading="transferingQuestions.includes(question.id)"
          @click="transferQuestion(question.id)"
        >
          <font-awesome-icon :icon="`arrow-${direction}`" />
        </base-button>
      </table-data>
    </tr>
    <tr
      v-for="voicecall in animalHistory.voicecalls"
      :key="voicecall.id"
      class="bg-yellow-100 text-left"
    >
      <table-data>
        <div class="flex flex-col space-y-1">
          <p>
            <strong>Voidecall </strong>
            <span>({{ voicecall.id }})</span>
          </p>
        </div>
      </table-data>
      <table-data>
        <div class="flex flex-col space-y-1">
          <p>
            {{ voicecall.created_at }}
          </p>
          <p>
            {{ voicecall.status }}
          </p>
        </div>
      </table-data>
      <table-data />
      <table-data>
        {{ voicecall.veterinary ? voicecall.veterinary.display_name : '-' }}
      </table-data>
      <table-data>
        <base-button
          :loading="transferingVoiceCalls.includes(voicecall.id)"
          @click="transferVoiceCall(voicecall.id)"
        >
          <font-awesome-icon :icon="`arrow-${direction}`" />
        </base-button>
      </table-data>
    </tr>
    <tr
      v-if="animalHistory.appointments && !animalHistory.appointments.length
        && animalHistory.questions && !animalHistory.questions.length
        && animalHistory.voicecalls && !animalHistory.voicecalls.length"
    >
      <td
        colspan="6"
        class="py-3 px-6 text-base"
      >
        The animal has no previous appointments, questions or voicecalls.
      </td>
    </tr>
  </table-container>
</template>

<script>
import { mapActions } from 'vuex';
import { AnimalsApi } from '@/api';

export default {
  props: {
    animalKey: {
      type: String,
      required: true,
      validator(value) {
        return ['animal', 'compareAnimal'].includes(value);
      },
    },
    compareAnimalKey: {
      type: String,
      required: true,
      validator(value) {
        return ['animal', 'compareAnimal'].includes(value);
      },
    },
    direction: {
      type: String,
      default: 'right',
    },
  },

  data() {
    return {
      transferingAppointments: [],
      transferingQuestions: [],
      transferingVoiceCalls: [],
    };
  },

  computed: {
    animal() {
      return this.$store.state.admin.animals[this.animalKey];
    },
    animalHistory() {
      return this.$store.state.admin.animals[`${this.animalKey}History`];
    },
    compareAnimal() {
      return this.$store.state.admin.animals[this.compareAnimalKey];
    },
    compareAnimalHistory() {
      return this.$store.state.admin.animals[`${this.compareAnimalKey}History`];
    },
    animalHistoryLoading() {
      return this.$store.state.admin.animals[`${this.animalKey}HistoryLoading`];
    },
  },

  methods: {
    ...mapActions('admin/animals', ['fetchAnimalHistory']),

    async transferAppointment(id) {
      this.transferingAppointments.push(id);

      await AnimalsApi.transferAppointment(
        id,
        this.compareAnimal.user_id,
        this.compareAnimal.id
      );

      this.transferingAppointments = this.transferingAppointments.filter(
        a => a !== id
      );

      this.fetchAnimalHistories();
    },

    async transferQuestion(id) {
      this.transferingQuestions.push(id);

      await AnimalsApi.transferQuestion(
        id,
        this.compareAnimal.user_id,
        this.compareAnimal.id
      );

      this.transferingQuestions = this.transferingQuestions.filter(
        q => q !== id
      );

      this.fetchAnimalHistories();
    },

    async transferVoiceCall(id) {
      this.transferingVoiceCalls.push(id);

      await AnimalsApi.transferVoiceCall(
        id,
        this.compareAnimal.user_id,
        this.compareAnimal.id
      );

      this.transferingVoiceCalls = this.transferingVoiceCalls.filter(
        q => q !== id
      );

      this.fetchAnimalHistories();
    },

    fetchAnimalHistories() {
      this.fetchAnimalHistory({
        id: this.animal.id,
        animalKey: this.animalKey,
      });
      this.fetchAnimalHistory({
        id: this.compareAnimal.id,
        animalKey: this.compareAnimalKey,
      });
    },
  },
};
</script>
