<template>
  <div
    class="flex justify-between"
  >
    <div class="flex flex-col flex-1 bg-white text-gray-800 shadow rounded p-5">
      <h2
        class="font-semibold text-lg mb-4 flex justify-between"
      >
        Share
        <font-awesome-icon
          icon="share"
          :class="{'fa-flip-horizontal': direction === 'left'}"
        />
      </h2>
      <p class="text-sm mb-4">
        <template v-if="sameAnimalOwner">
          The animals both have <strong>{{ animal.user && animal.user.display_name }}</strong>
          as owner and can therefore not be shared.
        </template>
        <template v-else-if="animalAlreadyShared">
          Animal is shared with <strong>{{ compareAnimal.user && compareAnimal.user.display_name }}</strong>.
        </template>
        <template v-else>
          Animal will be shared with <strong>{{ compareAnimal.user && compareAnimal.user.display_name }}</strong>.
        </template>
      </p>
      <base-button
        class="mt-auto mx-auto"
        :disabled="sameAnimalOwner || animalAlreadyShared"
        :loading="sharingAnimal"
        @click="shareAnimal"
      >
        Share
      </base-button>
    </div>
    <div
      class="flex flex-col flex-1 bg-yellow-200 text-yellow-900 shadow rounded p-5"
    >
      <h2 class="font-semibold text-lg mb-4 flex justify-between">
        Change owner
        <font-awesome-icon
          icon="arrow-right"
          :class="{'fa-flip-horizontal': direction === 'left'}"
        />
      </h2>
      <p class="text-sm mb-4">
        <template v-if="sameAnimalOwner">
          The animals both have <strong>{{ animal.user && animal.user.display_name }}</strong>
          as owner and can therefore not be transfered.
        </template>
        <template v-else>
          Animal will be owned by <strong>{{ compareAnimal.user && compareAnimal.user.display_name }}</strong>
          and shared with <strong>{{ animal.user && animal.user.display_name }}</strong>.
        </template>
      </p>

      <label
        v-if="!sameAnimalOwner && animalHasHistory"
        class="text-xs flex justify-center items-center mb-5"
      >
        <input
          v-model="transferHistory"
          class="mr-2"
          type="checkbox"
        >
        Change ownership of previous appointments, questions and voice calls.
      </label>

      <base-button
        class="mt-auto mx-auto"
        :disabled="sameAnimalOwner"
        :loading="transferingAnimal"
        @click="transferAnimal"
      >
        Change owner
      </base-button>
    </div>
    <div class="flex flex-col flex-1 bg-red-200 text-red-800 shadow rounded p-5">
      <h2 class="font-semibold text-lg mb-4 flex justify-between">
        Delete
        <font-awesome-icon icon="trash" />
      </h2>
      <p
        class="text-sm mb-4"
      >
        {{ animalHasHistory ? 'Animal cannot be removed when it has appointments or questions.'
          : 'If you delete the animal all it\'s data will be permanently removed.' }}
      </p>
      <base-button
        class="mt-auto mx-auto"
        :disabled="animalHasHistory"
        @click="showDeleteConfirm = true"
      >
        Delete
      </base-button>
    </div>

    <base-modal
      :show="showDeleteConfirm"
      @close="showDeleteConfirm = false"
    >
      <h1 class="text-lg mb-2">
        Delete
      </h1>
      <p>Are you sure you want to permanently delete <strong>{{ animal.name }}</strong>?</p>
      <template slot="buttons">
        <base-button
          color="cancel"
          @click="showDeleteConfirm = false"
        >
          Cancel
        </base-button>
        <base-button
          color="danger"
          :loading="deletingAnimal"
          @click="deleteAnimal"
        >
          Delete
        </base-button>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { AnimalsApi } from '@/api';

export default {
  props: {
    animalKey: {
      type: String,
      required: true,
      validator(value) {
        return ['animal', 'compareAnimal'].includes(value);
      },
    },
    compareAnimalKey: {
      type: String,
      required: true,
      validator(value) {
        return ['animal', 'compareAnimal'].includes(value);
      },
    },
    direction: {
      type: String,
      default: 'right',
    },
  },

  data() {
    return {
      transferHistory: true,
      showDeleteConfirm: false,
      sharingAnimal: false,
      transferingAnimal: false,
      deletingAnimal: false,
    };
  },

  computed: {
    animal() {
      return this.$store.state.admin.animals[this.animalKey];
    },
    animalHistory() {
      return this.$store.state.admin.animals[`${this.animalKey}History`];
    },
    compareAnimal() {
      return this.$store.state.admin.animals[this.compareAnimalKey];
    },
    animalAlreadyShared() {
      return (
        this.animal.shared?.length > 0 &&
        this.animal.shared.findIndex(
          s => s.user_id === this.compareAnimal.user_id
        ) > -1
      );
    },
    sameAnimalOwner() {
      return this.animal.user_id === this.compareAnimal.user_id;
    },
    animalHasHistory() {
      return (
        this.animalHistory?.appointments?.length > 0 ||
        this.animalHistory?.questions?.length > 0
      );
    },
  },

  methods: {
    ...mapMutations('admin/animals', ['setAnimal']),
    ...mapActions('admin/animals', [
      'fetchAnimalData',
      'fetchAnimalHistory',
      'clearAnimalsForCompare',
    ]),

    async shareAnimal() {
      this.sharingAnimal = true;

      const shared = await AnimalsApi.shareAnimal(
        this.animal.id,
        this.compareAnimal.user_id
      );

      this.sharingAnimal = false;

      if (shared?.data?.animal) {
        this.setAnimal({ animalKey: this.animalKey, data: shared.data.animal });

        this.$notify({
          group: 'primary',
          title: 'Success!',
          text: `${shared.data.animal.name} was shared with ${
            // eslint-disable-next-line camelcase
            this.compareAnimal.user?.display_name
          }`,
        });
      }
    },
    async transferAnimal() {
      this.transferingAnimal = true;

      const transfered = await AnimalsApi.transferAnimal(
        this.animal.id,
        this.compareAnimal.user_id,
        this.transferHistory
      );

      this.transferingAnimal = false;

      if (transfered?.data?.animal) {
        this.setAnimal({
          animalKey: this.animalKey,
          data: transfered.data.animal,
        });

        if (this.transferHistory) {
          this.fetchAnimalHistory({
            id: this.animal.id,
            animalKey: this.animalKey,
          });
        }

        this.$notify({
          group: 'primary',
          title: 'Success!',
          text: `${transfered.data.animal.name} was moved to ${
            // eslint-disable-next-line camelcase
            this.compareAnimal.user?.display_name
          }`,
        });
      }
    },
    async deleteAnimal() {
      this.deletingAnimal = true;

      const deleted = await AnimalsApi.deleteAnimal(this.animal.id);

      this.deletingAnimal = false;

      if (deleted?.status === 200) {
        this.$notify({
          group: 'primary',
          title: 'Success!',
          text: `${this.animal.name} was deleted`,
        });
        this.clearAnimalsForCompare();
        this.$router.push({ name: 'animals' });
      }
    },
  },
};
</script>
