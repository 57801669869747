var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-between" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col flex-1 bg-white text-gray-800 shadow rounded p-5",
        },
        [
          _c(
            "h2",
            { staticClass: "font-semibold text-lg mb-4 flex justify-between" },
            [
              _vm._v(" Share "),
              _c("font-awesome-icon", {
                class: { "fa-flip-horizontal": _vm.direction === "left" },
                attrs: { icon: "share" },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "text-sm mb-4" },
            [
              _vm.sameAnimalOwner
                ? [
                    _vm._v(" The animals both have "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.animal.user && _vm.animal.user.display_name)
                      ),
                    ]),
                    _vm._v(" as owner and can therefore not be shared. "),
                  ]
                : _vm.animalAlreadyShared
                ? [
                    _vm._v(" Animal is shared with "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.compareAnimal.user &&
                            _vm.compareAnimal.user.display_name
                        )
                      ),
                    ]),
                    _vm._v(". "),
                  ]
                : [
                    _vm._v(" Animal will be shared with "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.compareAnimal.user &&
                            _vm.compareAnimal.user.display_name
                        )
                      ),
                    ]),
                    _vm._v(". "),
                  ],
            ],
            2
          ),
          _c(
            "base-button",
            {
              staticClass: "mt-auto mx-auto",
              attrs: {
                disabled: _vm.sameAnimalOwner || _vm.animalAlreadyShared,
                loading: _vm.sharingAnimal,
              },
              on: { click: _vm.shareAnimal },
            },
            [_vm._v(" Share ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col flex-1 bg-yellow-200 text-yellow-900 shadow rounded p-5",
        },
        [
          _c(
            "h2",
            { staticClass: "font-semibold text-lg mb-4 flex justify-between" },
            [
              _vm._v(" Change owner "),
              _c("font-awesome-icon", {
                class: { "fa-flip-horizontal": _vm.direction === "left" },
                attrs: { icon: "arrow-right" },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "text-sm mb-4" },
            [
              _vm.sameAnimalOwner
                ? [
                    _vm._v(" The animals both have "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.animal.user && _vm.animal.user.display_name)
                      ),
                    ]),
                    _vm._v(" as owner and can therefore not be transfered. "),
                  ]
                : [
                    _vm._v(" Animal will be owned by "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.compareAnimal.user &&
                            _vm.compareAnimal.user.display_name
                        )
                      ),
                    ]),
                    _vm._v(" and shared with "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.animal.user && _vm.animal.user.display_name)
                      ),
                    ]),
                    _vm._v(". "),
                  ],
            ],
            2
          ),
          !_vm.sameAnimalOwner && _vm.animalHasHistory
            ? _c(
                "label",
                {
                  staticClass: "text-xs flex justify-center items-center mb-5",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.transferHistory,
                        expression: "transferHistory",
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.transferHistory)
                        ? _vm._i(_vm.transferHistory, null) > -1
                        : _vm.transferHistory,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.transferHistory,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.transferHistory = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.transferHistory = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.transferHistory = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " Change ownership of previous appointments, questions and voice calls. "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "base-button",
            {
              staticClass: "mt-auto mx-auto",
              attrs: {
                disabled: _vm.sameAnimalOwner,
                loading: _vm.transferingAnimal,
              },
              on: { click: _vm.transferAnimal },
            },
            [_vm._v(" Change owner ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col flex-1 bg-red-200 text-red-800 shadow rounded p-5",
        },
        [
          _c(
            "h2",
            { staticClass: "font-semibold text-lg mb-4 flex justify-between" },
            [
              _vm._v(" Delete "),
              _c("font-awesome-icon", { attrs: { icon: "trash" } }),
            ],
            1
          ),
          _c("p", { staticClass: "text-sm mb-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.animalHasHistory
                    ? "Animal cannot be removed when it has appointments or questions."
                    : "If you delete the animal all it's data will be permanently removed."
                ) +
                " "
            ),
          ]),
          _c(
            "base-button",
            {
              staticClass: "mt-auto mx-auto",
              attrs: { disabled: _vm.animalHasHistory },
              on: {
                click: function ($event) {
                  _vm.showDeleteConfirm = true
                },
              },
            },
            [_vm._v(" Delete ")]
          ),
        ],
        1
      ),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showDeleteConfirm },
          on: {
            close: function ($event) {
              _vm.showDeleteConfirm = false
            },
          },
        },
        [
          _c("h1", { staticClass: "text-lg mb-2" }, [_vm._v(" Delete ")]),
          _c("p", [
            _vm._v("Are you sure you want to permanently delete "),
            _c("strong", [_vm._v(_vm._s(_vm.animal.name))]),
            _vm._v("?"),
          ]),
          _c(
            "template",
            { slot: "buttons" },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.showDeleteConfirm = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "base-button",
                {
                  attrs: { color: "danger", loading: _vm.deletingAnimal },
                  on: { click: _vm.deleteAnimal },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }