var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-between text-sm" }, [
    _c(
      "div",
      [
        _c("h3", { staticClass: "font-bold" }, [_vm._v(" Shared with ")]),
        _vm._l(_vm.animal.shared, function (shared) {
          return _c("p", { key: shared.id }, [
            _vm._v(
              " " +
                _vm._s(shared.user ? shared.user.display_name : "") +
                " (" +
                _vm._s(shared.user_id) +
                ") "
            ),
          ])
        }),
        _vm.animal.shared && !_vm.animal.shared.length
          ? _c("p", [_vm._v(" - ")])
          : _vm._e(),
      ],
      2
    ),
    _c("div", [
      _c("h3", { staticClass: "font-bold" }, [_vm._v(" Owned by ")]),
      _c("p", [
        _vm._v(
          " " +
            _vm._s(_vm.animal.user ? _vm.animal.user.display_name : "") +
            " (" +
            _vm._s(_vm.animal.user_id) +
            ") "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }